import React from "react";
import { Link } from "gatsby";

import AboutUsImg from "../../../images/about-us-img.svg";

const About = () => {
  return (
    <section className="about">
      <div className="about-content container">
        <div className="about-content-left" data-aos="fade-down">
          <h3 className="heading-secondary about-content-left-heading">
            We are passionate about your success
          </h3>
          <p className="paragraph-primary about-content-left-text">
            As a software development company rooted in strong values, we focus on custom software, digital transformation, and IT consultancy. We help businesses in industries like Retail, Finance, and Insurance harness the potential of modern technologies.
          </p>
          <p className="paragraph-primary about-content-left-text">
            Our approach emphasizes understanding your unique needs to deliver software that drives results. For us, every project is an opportunity to support your growth by providing solutions that truly make a difference.
          </p>
          <div className="about-content-left-btn">
            <Link to="/about" className="btn btn-primary">
              Get to know us better
            </Link>
          </div>
        </div>
        <div className="about-content-right" data-aos="fade-up">
          <div className="about-content-right-item">
            <img src={AboutUsImg} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
