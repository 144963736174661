import { Link } from "gatsby";
import React from "react";

import RectangleImg from "../../../images/rectangle.svg";
import SkewRectangleHeroImg from "../../../images/skewRectangleHero.png";

const HeroSection = () => {
  return (
    <section
      className="hero-section"
      style={{ backgroundImage: `url(${SkewRectangleHeroImg})` }}
    >
      <div className="hero-section-content container" data-aos="fade-up">
        <div className="hero-section-content-left">
          <h1 className="heading-primary">
            Technology solutions that support businesses
          </h1>
          <p className="paragraph-primary">
            With over a decade of experience, we create custom solutions that address real business needs. Our services include requirement analysis, design, implementation, and ongoing support.
          </p>
          <p className="paragraph-primary">
            Visit our <Link to="/portfolio">portfolio</Link> to see how we've helped businesses overcome challenges with tailored technology.
          </p>
        </div>
        <div className="hero-section-content-right" data-aos="fade-left">
          <img src={RectangleImg} alt="" />
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
