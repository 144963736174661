import React from "react";

import TechnologiesImg from "../../../images/technologies.png";

const Technologies = () => {
  return (
    <section className="technologies">
      <div className="technologies-content container">
        <div className="technologies-content-left" data-aos="fade-up">
          <div className="technologies-content-left-item">
            <img loading="lazy" src={TechnologiesImg} alt="" />
          </div>
        </div>
        <div className="technologies-content-right" data-aos="fade-down">
          <h3 className="heading-secondary">
            Guided by expertise and teamwork
          </h3>
          <p className="paragraph-primary">
            Our team specializes in programming languages like Python, Java, Typescript and React, combining technical skill with a commitment to staying current. We focus on building practical solutions that solve real problems.
          </p>
          <p className="paragraph-primary">
            What makes us different is our ability to collaborate across disciplines. By combining knowledge from various fields, we deliver solutions that address both the specific needs of your project and the bigger picture.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Technologies;
