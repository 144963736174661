import React from "react";

import AssecoLogo from "../../../images/clients/asseco.svg";
import AsariLogo from "../../../images/clients/asari.svg";
import MediacorpLogo from "../../../images/clients/mediacorp.svg";
import SapiensLogo from "../../../images/clients/sapiens.svg";
import AutoriaLogo from "../../../images/clients/autoria.svg";
import EcobinsLogo from "../../../images/clients/ecobins.svg";
import SGGWLogo from "../../../images/clients/sggw.svg";
import TorunLogo from "../../../images/clients/torun.svg";
import ITFactoryLogo from "../../../images/clients/it-factory.svg";
import AssecoDataSystemsLogo from "../../../images/clients/asseco-data-systems.svg";

const ClientsSection = () => {
  return (
    <section className="about-clients" data-aos="fade-up" data-aos-delay="100">
      <div className="about-clients-content container">
        <ul className="about-clients-content-wrapper">
          <li className="about-clients-content-wrapper-item">
            <a href="https://pl.asseco.com/" target="_blank" rel="noreferrer">
              <img src={AssecoLogo} alt="" />
            </a>
          </li>
          <li className="about-clients-content-wrapper-item">
            <a
              href="https://it-factory.pl"
              target="_blank"
              rel="noreferrer"
            >
              <img src={ITFactoryLogo} alt="" />
            </a>
          </li>
          <li className="about-clients-content-wrapper-item">
            <a
              href="https://www.assecods.pl"
              target="_blank"
              rel="noreferrer"
            >
              <img src={AssecoDataSystemsLogo} alt="" />
            </a>
          </li>
          <li className="about-clients-content-wrapper-item">
            <a
              href="https://www.mediacorp.sg/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={MediacorpLogo} alt="" />
            </a>
          </li>
          <li className="about-clients-content-wrapper-item">
            <a href="https://asaricrm.com/" target="_blank" rel="noreferrer">
              <img src={AsariLogo} alt="" />
            </a>
          </li>
          <li className="about-clients-content-wrapper-item">
            <a href="https://sapiens.com/" target="_blank" rel="noreferrer">
              <img src={SapiensLogo} alt="" />
            </a>
          </li>
          <li className="about-clients-content-wrapper-item">
            <a href="https://autoria.pl/" target="_blank" rel="noreferrer">
              <img src={AutoriaLogo} alt="" />
            </a>
          </li>
          <li className="clients-content-wrapper-item">
            <a href="https://www.sggw.edu.pl/" target="_blank" rel="noreferrer">
              <img src={SGGWLogo} alt="" />
            </a>
          </li>
          <li className="clients-content-wrapper-item">
            <a href="https://www.torun.pl/" target="_blank" rel="noreferrer">
              <img src={TorunLogo} alt="" />
            </a>
          </li>
          <li className="about-clients-content-wrapper-item">
            <a href="http://ecobins.pl/" target="_blank" rel="noreferrer">
              <img src={EcobinsLogo} alt="" />
            </a>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default ClientsSection;
