import * as React from "react";
import { Link } from "gatsby";
import ArrowButton from "../../ArrowButton";

import WorldMapImg from "../../../images/world-map.png";

const HeroSection = () => {
  return (
    <section
      className="hero"
      style={{ backgroundImage: `url(${WorldMapImg})` }}
    >
      <div className="hero-text container">
        <h1 className="heading-primary">
          We transform your ideas into innovative software solutions
        </h1>
        <p className="paragraph-primary">
          At GammaSoft, we deliver tailored software to address your needs, from automating workflows to managing data or building custom applications — all designed to drive measurable results.        </p>
        <div className="hero-buttons">
          <Link to="/services" className="btn btn-secondary">
            <ArrowButton text="Explore our services" />
          </Link>

          <Link to="/portfolio" className="btn btn-primary">
            View our projects
          </Link>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
